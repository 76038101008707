.inputs_boxs_modal {
    /*border: 1px solid red;*/
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    row-gap: 16px;
    column-gap: 12px;
}

.input_range_box_inputs {
    display: flex;
    align-items: center;
    /*gap: 12px;*/
    gap: 4px;
    width: 100%;
    justify-content: space-between;
}

.input_range_box_inputs_inside {
    display: flex;
    align-items: center;
    gap: 12px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
}

.action_right {
    display: flex;
    align-items: center;
    gap: 12px;
}