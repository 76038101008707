.header_item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_item svg {
    transition: .3s all;
}

.header_item_active svg {
    rotate: 180deg;
}
