.main {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 14px;
    height: 78px;
}

.trade_item {
    width: 100%;
    background: linear-gradient(90deg, #1f1f1f 0%, rgba(31, 31, 31, 0) 100%);
    border: 1px solid #3C3C3C;
    height: 100%;
    border-radius: 10px;
    padding: 14px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.trade_item_left {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.trade_item_left_title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    color: #BDBDBD;
}

.trade_item_left_sub_title {
    font-family: Montserrat, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.6px;
    text-align: left;
    color: #FFFFFF;
}

.trade_item_left_sub_title span {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: right;
    color: #6FCF97;
}

.trade_item_right {
    height: fit-content;
    padding: 8px 14px;
    background: #413A25;
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: right;
    color: #F2C94C;
}

@media screen and (max-width: 980px) {
    .main {
        display: grid;
        grid-template-columns: 1fr;
        gap: 14px;
        height: fit-content;
    }
}