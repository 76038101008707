html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  //overflow-x: hidden;
  font-style: normal;
}

body {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}


select,
input,
button{
  background-color: transparent;
  border: none;
  outline: none;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
