.btn {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3C3C3C;
    border-radius: 5px;
    padding: 8px 14px;
    width: fit-content;
    transition: .3s all;
    cursor: pointer;
    color: #BDBDBD;
}

.btn:hover {
    border: 1px solid #626262;
    color: #949494
}
