.main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
}

.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
}

.header_right {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
}

.item {
    min-height: 110px;
    border: 1px solid #3C3C3C;
    background: #FFFFFF05;
    border-radius: 10px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item_r_t_title_f, .item_r_t_title_s {
    font-family: Montserrat, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.6px;
    text-align: left;
    color: #fff
}

.item_r_t_title_s {
    color: #F2C94C;
}

.item_r_b {
    padding: 14px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 14px;
}

.item_r_b_item {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
}

.item_r_b_item_title {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    color: #BDBDBD;
}

.item_r_b_item_subtitle {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: right;
    color: #FFFFFF;
}

.item_r_b_item_subtitle_green {
    color: #6FCF97;
}

.header_left {
    justify-content: center;
    padding: 14px;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    /*background: #000;*/
}

.item_r_t {
    /*background: #000;*/
}

.header_left_top, .header_left_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}

.header_left_top_title {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 250;
    line-height: 14.63px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #FFFFFF;
}

.header_left_bottom_title_green {
    font-family: Montserrat, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.6px;
    text-align: center;
    color: #6FCF97;
}

.header_left_bottom_title {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: center;
    color: #FFFFFF;
}

.btn_url {
    width: 100%;
    height: 100%;
    padding: 8px 14px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    color: #BDBDBD;
}

.button_action {
    max-width: 300px !important;
    width: 100% !important;
}

.button_action_url {
    padding: 0 !important;
}

@media screen and (max-width: 980px) {

    .header {
        grid-template-columns: 1fr;
    }
}