.main {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
}

.title {
    font-family: Montserrat, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.6px;
    text-align: left;
    color: #fff;
}

.items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    height: 78px;
    background: linear-gradient(90deg, #1f1f1f 0%, rgba(31, 31, 31, 0) 100%);
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    padding: 0px 28px;
}

.item_left {
    display: flex;
    align-items: center;
    gap: 14px;
}

.item_left_title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    color: #fff;
}

.item_right {
    display: flex;
    align-items: center;
    gap: 14px;
    /*border: 1px solid red;*/
    height: 100%;
}

.item_right_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.item_right_title {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    text-align: center;
    color: #BDBDBD;
}

.item_right_sub_title {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: center;
    color: #F2C94C;
}

.item_right_sub_title_green {
    color: #6FCF97;
}

.line {
    height: calc(100% - 28px);
    width: 1px;
    background: #3C3C3C;
    /*background: red;*/
}