.negative_answer {
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    /*border: 1px solid red;*/
    margin-top: -28px;
    padding: 0 28px;
    /*margin-top: 28px;*/
}

@media screen and (max-width: 979px) {
    .negative_answer {
        min-height: 230px;
    }
}
