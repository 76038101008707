.settings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    height: 100%;
}

.ref_box {
    padding: 28px;
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    background: #1F1F1F;
    height: 100%;
}

.title {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ref_box_action {
    padding: 14px;
    border-radius: 5px;
    border: 0.5px solid #3C3C3C;
    margin-top: 14px;
}

.line {
    width: calc(100% + 28px);
    height: 1px;
    background: #3C3C3C;
    margin-top: 14px;
}

@media screen and (max-width: 979px) {
    .settings {
        grid-template-columns: 1fr;
    }

    .ref_box {
        padding: 16px;
    }
}
