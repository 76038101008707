.info_box .info_box_item p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #BDBDBD;
    margin-left: 8px;
}

.info_box {
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
}

.info_box .info_box_item div {
    height: 8px;
    width: 28px;
    border-radius: 5px;
}

.info_box .info_box_item {
    margin-top: 11px;
    width: 100%;
    display: flex;
    align-items: center;
    /*margin-left: 50px;*/
    /*justify-content: center;*/

}
