@import "colors";

body {
  box-sizing: inherit;
  overflow: hidden;
}

.main {
  margin-bottom: auto;
}

.input_number {
  border: none;
  outline: none;
  background: #3C3C3C;
  border-radius: 5px;
  padding: 4px 14px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  max-width: 50px;
  justify-content: center;
}

.input_number::-webkit-outer-spin-button,
.input_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.footer {
  margin-top: 28px;
  padding-bottom: 20px;
}

.footer_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_top_left_subtitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: #828282;
}

.footer-box {
  width: fit-content;
  display: flex;
  width: 100%;
}

.footer_top_right {
  height: 44px;
  width: auto
}

.footer_middle, .footer_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.footer_bottom_title, .footer_bottom_subtitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #828282;
}

.exit {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #3C3C3C;
  border-radius: 5px;
  padding: 9px 14px;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: auto;
}

.exit svg {
  margin-right: 8px;
}

.footer_middle_right {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #828282;
  max-width: 450px;
  //border: 1px solid red;
}

*::-webkit-scrollbar {
  width: 5px; /* ширина scrollbar */
}

.Toastify__toast--error {
  background-color: #2C2626 !important;
  color: #FF8888 !important;
  border: 0.5px solid #3C3C3C !important;
  border-radius: 5px !important;
  min-height: 100px !important;
  //padding: 0 30px !important;
  text-align: center !important;
  opacity: 1 !important;
}

.Toastify__toast--success {
  background-color: #29312C !important;
  color: #6FCF97 !important;
  border: 0.5px solid #3C3C3C !important;
  border-radius: 5px !important;
  min-height: 100px !important;
  //padding: 0 30px !important;
  text-align: center !important;
  opacity: 1 !important;
}

//.Toastify__toast--success svg {
//  display: none !important;
//}

*::-webkit-scrollbar-track {
  background: transparent; /* цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: $white-70; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}

* {
  scrollbar-width: thin;
  scrollbar-color: $white-70 transparent;
}

.h1 {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  text-align: left;
}

// Оглавление блоков, большие кнопки
.h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

// Сопровождение к элементам
.subHeaders {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.subHeadersBold {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.subHeadersRegular {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

//#chartcontrols .am5stock-control-button {
//  background: #1C1B1A;
//  color: #fff;
//}
//
//#chartcontrols .am5stock-control-button:hover {
//  background: rgb(73, 73, 73);
//  //color: #1C1C1C;
//}
//
//#chartcontrols .am5stock-no-hover:hover {
//  background: #1C1B1A;
//}
//
//#chartcontrols .am5stock-link:hover {
//  background: rgb(73, 73, 73);
//}
//
//#chartcontrols .am5stock-control-icon path {
//  stroke: #fff;
//}

.link {
  color: $blueHigh;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;
}

.download {
  color: $blueHigh;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
}

.header {
  margin-bottom: 14px;
}

.menuList {
  display: flex;
  flex-direction: column;
  gap: 14px;
  overflow: auto;
  height: 80vh;
  padding-bottom: 50px;
}

.menuUserList {
  & .menuItem {
    &:nth-child(6) {
      margin-top: auto;

      &:before {
        background: $blueHigh;
      }
    }
  }
}


.menuItem {
  @extend .h2;
  padding: 14px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    background: $white-70;
    position: absolute;
    top: 0;
    left: 0;
    color: $white-100;
  }

  &.tariff {
    margin-bottom: 26px;
  }

  //&.support {
  //  margin-top: 165px;
  //
  //}

  &.menuItemProduct {
    margin-bottom: 28px;
  }

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white-90;
  }

  a.active {
    color: $white-100;
  }

}

.white-100 {
  color: $white-100;
  fill: $greenHigh;
}

.white-90 {
  color: $white-90;
  fill: $greenHigh;
}

.white-80 {
  color: $white-80;
  fill: $greenHigh;
}

.white-70 {
  color: $white-70;
  fill: $greenHigh;
}

.white-60 {
  color: $white-60;
  fill: $greenHigh;
}

.green {
  color: $greenHigh;
  fill: $greenHigh;
}

.greenBg {
  background-color: $greenLow;
}

.red {
  color: $redHigh;
  fill: $redHigh;
}

.redBg {
  background-color: $redLow;
}

.purple {
  color: $purpleHigh;
  fill: $purpleHigh;
}

.purpleBg {
  background-color: $purpleLow;
}


.pink {
  color: $pinkHigh;
  fill: $pinkHigh;
}

.pinkBg {
  background-color: $pinkLow;
}

.blue {
  color: $blueHigh;
  fill: $blueHigh;
}

.blueBg {
  background-color: $blueLow;
}

.yellow {
  color: $yellowHigh;
  fill: $yellowHigh;
}

.yellowBg {
  background-color: $yellowLow;
}

.orange {
  color: $orangeHigh;
  fill: $orangeHigh;
}

.orangeBg {
  background-color: $orangeLow;
}

.navigationYellow {
  &:before {
    background: $grNavigationYellow;
  }
}

.navigationGreen {
  &:before {
    background: $grNavigationGreen;
  }
}

.navigationPurple {
  &:before {
    background: $grNavigationPurple;
  }
}

.navigationRed {
  &:before {
    background: $grNavigationRed;
  }
}

.navigationWhite {
  &:before {
    background: $grNavigationWhite;
  }
}

.recharts-wrapper {
  cursor: pointer !important;
}

.recharts-rectangle.recharts-tooltip-cursor {
  fill: transparent;
}

.MuiSlider-thumb {
  z-index: 10 !important;
}

@media screen and (max-width: 1220px) {
  .App {
    flex-direction: column;
  }

  .exit {
    margin-bottom: 0px;

  }
}

@media screen and (max-width: 980px) {


  .footer_middle {
    flex-direction: column;
  }

  .footer-box {
    width: 100%;
  }
  .footer-box a {
    width: 100% !important;
  }

  .footer_middle_right {
    text-align: left;
    margin-top: 20px;
  }

  .exit {
    //margin-top: calc(165px * 2 - 90px);
  }
}

@media screen and (max-width: 560px) {
  .title_chart_dashboard {
    font-size: 16px;
  }

  .footer-box {
    flex-direction: column;
  }


}

@media screen and (max-width: 520px) {
  .footer-box {
    width: 100%;
  }
}

.rdrDateDisplayWrapper, .rdrMonthAndYearWrapper, .rdrMonthsVertical {
  background-color: rgba(31, 31, 31, 1) !important;
}

.rdrDayNumber span {
  color: #fff !important;
}

.rdrDayWeekend span {
  color: #f85e5e !important;
}

.rdrDayWeekend .rdrInRange {
  color: #6FCF97 !important;
  background-color: rgba(41, 49, 44, 1) !important;
}

.rdrDayWeekend .rdrInRange span {
  color: #6FCF97 !important;
  background-color: rgba(41, 49, 44, 1) !important;
}

.rdrDayPassive span {
  color: #cecece !important;
  cursor: not-allowed !important;
}

.rdrDayPassive {
  cursor: not-allowed !important;
}

.rdrInRange {
  color: #6FCF97 !important;
  //background-color: #6FCF97 !important;
  background-color: rgba(41, 49, 44, 1) !important;
}

.rdrEndEdge {
  color: #6FCF97 !important;
  background-color: #6FCF97 !important;
}

.rdrStartEdge {
  color: #6FCF97 !important;
  background-color: #6FCF97 !important;
}

.rdrDateInput {
  background-color: rgba(41, 49, 44, 1) !important;
  border: 1px solid #6FCF97 !important;
}

.rdrDateDisplayItem {
  background-color: rgba(41, 49, 44, 1) !important;
  border: 1px solid #6FCF97 !important;
}

.rdrDateInput input {
  color: #fff !important;
}

.rdrDateInput input::placeholder {
  color: #fff !important;
}

.rdrDateDisplayItemActive {
  background-color: rgba(111, 207, 151, 0.4) !important;
}


.rdrMonthAndYearPickers select {
  color: #fff !important;
}

.rdrMonthAndYearPickers select option {
  color: #000 !important;
}

.rdrNextPrevButton, .rdrNextButton, .rdrYearPicker, .rdrMonthPicker {
  background: rgba(41, 49, 44, 1) !important;
  border: 1px solid #6FCF97 !important;

}

.rdrYearPicker, .rdrMonthPicker {
  width: 50% !important;
}

.rdrNextButton i {
  border-color: transparent transparent transparent #fff !important;
}

.rdrPprevButton i {
  border-color: transparent #fff transparent transparent !important;
}

.rdrDayDisabled {
  background-color: inherit !important;
}

.rdrDayDisabled span {
  color: #cecece !important;
}

.hiddeSideBarIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hiddeSideBarIcon svg {
  margin: 0 auto;
}

.hidden_button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

