.product {
    position: relative;
    height: 100%;
    width: 100%;
    /*max-width: 952px;*/
    /*border: 1px solid red;*/
}

@media screen and (max-width: 1220px) {
    .product {
        max-width: 100%;
    }
}

@media screen and (max-width: 980px) {
    .img_product {
        height: 127px;
        width: auto;
        margin: 20px 0;
    }
}

