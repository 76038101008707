.navigate {
    display: flex;
    align-items: center;
    gap: 4px;
}

.title {
    font-family: Montserrat, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.6px;
    color: #828282;
}

.title_last {
    color: #fff;
}

@media screen and (max-width: 980px) {
    .title {
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
    }

    .navigate {
        width: 100%;
        justify-content: center;
    }
}