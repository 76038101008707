.box_step_1_title {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.box_step_1 {
    margin-top: 8px;
    display: flex;
}

.box_step_1_item {
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    padding: 14px 28px;
    margin-top: 8px;
}

.box_step_1_item:first-child {
    margin-top: 0;
}

.link {
    margin-top: 4px;
    color: #56CCF2;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-wrap: break-word;
}

.box_step_1_title span {
    color: #fff;
}

.big_sub_title {
    color: #6FCF97 !important;
}

.box_step_1_right {
    margin-left: 14px;
}

.box_step_1_right img {
    min-width: 173px;
    width: 100%;
    height: 100%;
}

.box_step_2 {
    margin-top: 14px;
}

.box_step_2_item {
    margin-top: 14px;
}

.important {
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    background: #413A25;
    padding: 14px 28px;
    margin-top: 8px;
}

.important_p {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.important_span {
    color: #F2C94C;
}

.important_sub_span {
    color: #fff;
}

.box_step_2_last {
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    padding: 14px 28px;
    margin-top: 28px;
}

.box_step_2_last_title {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.box_step_2_last_white {
    color: #fff;
}

.box_step_2_last_green {
    color: #6FCF97;
}

.last_img {
    margin-top: 8px;
}

@media screen and (max-width: 768px) {
    .box_step_1 {
        flex-direction: column;
    }

    .box_step_1_right {
        margin-top: 14px;
        margin-left: 0;
    }

    .box_step_1_right img {
        min-width: 100%;
        width: 100%;
        height: auto;
    }

    .last_img img {
        min-width: 100%;
        width: 100%;
        height: auto;
    }

    .box_step_1_title, .link {
        font-size: 15px;
    }
}

.btn_link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0.5px solid #3C3C3C;
    width: 100%;
}

.btn_link a {
    color: #56CCF2;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    height: 100%;
    padding: 14px 28px;
}
