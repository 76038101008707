.text_box {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #BDBDBD;
}

.text_box span {
    margin-left: 10px;
}

.action_box {
    margin-top: 0px;
}

.action {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}
