.content {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    /*border: 1px solid #fff;*/

}

.navigate {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;
    margin-bottom: 14px;
}

.navigate_item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 14px 28px;
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    cursor: pointer;
    transition: .4s all;
}

.title_item {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
    color: #828282
}

.navigate_item:hover {
    border: 1px solid #626262;
}

.navigate_item:hover .title_item {
    color: #949494
}

.navigate_item_active {
    background: #1F1F1F;
}

.navigate_item_active svg path {
    fill: #FFFFFF;
}

.navigate_item_active .title_item {
    color: #FFFFFF;
}

.navigate_item_active:hover {
    border: 1px solid #3C3C3C;
}

.navigate_item_active:hover .title_item {
    color: #FFFFFF
}

.showChartButtonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 28px);
}

.showChartButtonContentTable {
    height: 100px;
    /*border: 1px solid red;*/
}


.content_left {
    /*max-width: 65%;*/
    width: 100%;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}


.content_right {
    /*min-width: calc(30% + 20px);*/
    /*max-width: calc(30% + 20px);*/
    min-width: calc(300px);
    max-width: calc(300px);
    display: flex;
    /*margin-left: 30px;*/
    position: sticky;
    /*border: 1px solid red;*/

    max-height: 95vh;
    /*border: 1px solid red;*/
    overflow: auto;
    top: 2.5vh;
    padding-right: 10px;
    /*bottom: 20px;*/
    /*margin: 20px 0 20px 40px;*/

    border-radius: 10px;
}

@media screen and (max-width: 980px) {
    .content_left {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
    }

    .content_right {
        display: none;
        position: fixed;
        /*border: 1px solid #fff;*/
        /*z-index: 999999999;*/
        min-width: 100%;
        max-width: 100%;
        height: 100vh;
        max-height: 100vh;
        /*padding-bottom: 100px;*/

        /*overflow-y: auto;*/

        overflow: auto;
        overflow-x: hidden;
        /*opacity: 0;*/
        top: 0;
        left: 0;
        margin-left: 0px;
    }

    .navigate_stock {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 14px;
        margin-bottom: 14px;
        grid-template-areas:
    "item1 item2"
    "item3 item3"
    "item4 item4";
    }

    .navigate_item:nth-child(1) {
        grid-area: item1;
    }

    .navigate_item:nth-child(2) {
        grid-area: item2;
    }

    .navigate_item:nth-child(3) {
        grid-area: item3;
    }

    .navigate_item:nth-child(4) {
        grid-area: item4;
    }
}
