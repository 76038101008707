.step_3_title_box {
    margin-top: 14px;
}

.step_3_title_box h2 {
    color: #FFF;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.step_3_title_box p {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 8px;
}

.box_border {
    padding: 14px 28px;
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    width: 100%;
}

.box_border_v2 {
    padding: 14px 28px;
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    width: 100%;
    background: #413A25;
}

.box_border_v3 {
    padding: 14px 28px;
    border-radius: 10px;
    border: 0.5px solid #3C3C3C;
    width: 100%;
    background: #29312C;
}

.title_gray_white {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title_gray_white span {
    color: #fff;
}

.title_gray_yellow span {
    color: #F2C94C;
}

.link {
    color: #56CCF2;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 4px;
}

.step_3_1_img_box {
    margin-top: 8px;
    height: 70px;
}

.step_3_1_img_box img {
    width: 100%;
    height: 100%;
}

.step_3_grid_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 19px;
    margin-top: 8px;
}

.step_3_grid_box_left {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
}

.title_gray {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.important_sub_title {
    color: #F2C94C;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 4px;
}

.important_sub_title_green {
    color: #6FCF97;
    margin-top: 0;
    font-weight: 500;
}

.step_3_grid_box_right {
    height: 100%;
}

.img_box_step4 {
    width: 100%;
    margin-top: 8px;
}

.img_box_step4 img {
    width: 100%;
}

.title_green {
    color: #6FCF97;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.step_box_6_sub_title {
    color: #BDBDBD;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
}

.btn_link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0.5px solid #3C3C3C;
    width: 100%;
}

.btn_link a {
    color: #56CCF2;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    height: 100%;
    padding: 14px 28px;
}

@media screen and (max-width: 768px) {
    .step_3_title_box p {
        text-align: center;
    }

    .title_gray_white, .link, .title_gray, .important_sub_title {
        font-size: 15px;
    }

    .link {
        word-wrap: break-word;
    }

    .step_3_1_img_box {
        margin-top: 8px;
        height: fit-content;
    }

    .step_3_1_img_box img {
        width: 100%;
        height: auto;
    }

    .step_3_grid_box {
        display: grid;
        grid-template-columns:1fr;
        grid-column-gap: 19px;
        margin-top: 8px;

    }

    .step_3_grid_box_right img {
        width: 100%;
        height: auto;
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .step_3_grid_box_left {
        order: 2;
    }
}

