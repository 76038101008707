.step {
    color: #FFF;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 28px;
    border-radius: 5px;
    border: 0.5px solid #3C3C3C;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: fit-content;

}

.title {
    color: #FFF;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 768px) {
    .step{
        margin: 0 auto;
    }

    .title{
        text-align: center;
    }
}
