.main {
    min-width: 340px;
    max-width: 340px;
    position: sticky;
    top: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    height: fit-content;

}

.empty_box {
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    background: linear-gradient(90deg, #1F1F1F 0%, rgba(31, 31, 31, 0) 100%);
    padding: 14px;
    height: fit-content;

    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.6px;
    color: #cecece;
    text-align: center;
    min-width: 340px;
    max-width: 340px;
}

.wrapper {
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    background: linear-gradient(90deg, #1F1F1F 0%, rgba(31, 31, 31, 0) 100%);
    padding: 14px;
    height: fit-content;

}

.clear_wrapper {
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
}

.sub_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;

    /*height: calc(100vh - 28px - 148px - 26px - 78px - 50px - 28px - 32px - 28px - 28px - 14px);*/
    /*height: calc(100dvh - 28px - 148px - 26px - 78px - 50px - 28px - 32px - 28px - 28px - 14px);*/
    overflow: auto;
    height: calc(100vh - 340px);
}

.title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;

    color: #fff;
    margin-bottom: 12px;
}

.wrapper_input {
    width: 100%;
    border: 1px solid #303030;
    border-radius: 5px;
    padding: 4px 8px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.wrapper_input_left {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    /*width: 100%;*/
    gap: 8px;
}

.wrapper_input_switcher {
    margin-top: 8px;
    width: 100%;

}

/*.wrapper_input_left input {*/
/*    min-width: 100% !important;*/
/*    max-width: 100% !important;*/
/*    width: 100% !important;*/
/*}*/


.wrapper_input_clear {
    flex-direction: column;
    /*gap: 18px;*/
    padding: 8px;
}

.wrapper_input_v2 {
    justify-content: space-between;
}

.info_slide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
}

.btn_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: rgba(239, 185, 8, 0.5);
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.4);
        opacity: 0;
    }
}

.btn_save {
    position: relative;
}

.info_slide p {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    color: #828282;
}

.wrapper_input_v3 {
    flex-direction: column;
    padding-bottom: 14px;
}

.text {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    color: #fff;
}

.text_v2 {
    position: relative;
    width: fit-content;
}

.text_v2:before {
    content: '*';
    position: absolute;
    right: -7px;
    top: 0;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    color: #FF8888;
}

.title_blue {
    color: #56CCF2;
    margin-bottom: 0;
}

.green_box {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: center;
    color: #6FCF97;
    padding: 4px 10px;
    border: 1px solid #374F41;
    background: #29312C;
    border-radius: 5px;
    margin-left: 8px;
}

.btn_box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    width: 100%;

}

@media screen and (max-width: 980px) {
    .main {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        position: static;
        top: 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;

    }

    /*    const headers = [*/
    /*                    {*/
    /*                        title: '-',*/
    /*                        path: 'symbol',*/
    /*                        sort: 1,*/
    /*                    }, {*/
    /*    title: 'Канал %',*/
    /*    sort: 1,*/
    /*    path: 'channel_width'*/
    /*}, {*/
    /*    title: 'Доход общ.',*/
    /*    sort: 1,*/
    /*    path: 'summary_profit'*/
    /*}, {*/
    /*    title: 'Доход цены',*/
    /*    sort: 1,*/
    /*    path: 'price_profit'*/
    /*}, {*/
    /*    title: 'Доход куп.',*/
    /*    sort: 1,*/
    /*    path: 'coupon_profit'*/
    /*}, {*/
    /*    title: 'Оборот',*/
    /*    sort: 1,*/
    /*    path: 'turnover_in_money'*/
    /*}, {*/
    /*    title: 'Дата погашения',*/
    /*    sort: 1,*/
    /*    path: 'maturity_date'*/
    /*}, {*/
    /*    title: 'Оферта',*/
    /*    sort: 1,*/
    /*    path: 'offer_date'*/
    /*}, {*/
    /*    title: 'Выплата куп.',*/
    /*    sort: 1,*/
    /*    path: 'coupon_payment_dat'*/
    /*}, {*/
    /*    title: 'Размер куп.',*/
    /*    sort: 1,*/
    /*    path: 'coupon_size'*/
    /*}, {*/
    /*    title: 'НКД',*/
    /*    sort: 1,*/
    /*    path: 'nkd'*/
    /*}, {*/
    /*    title: 'Номинал',*/
    /*    sort: 1,*/
    /*    path: 'nominal'*/
    /*}, {*/
    /*    title: 'Рейтинг',*/
    /*    sort: 1,*/
    /*    path: 'r_common'*/
    /*}*/
    /*]*/
    .wrapper {
        border: none;
        border-radius: 10px;
        background: linear-gradient(90deg, #1F1F1F 0%, rgba(31, 31, 31, 0) 100%);
        padding: 0px;
        height: 100%;

    }

    .sub_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 14px;

        /*height: calc(100vh - 28px - 148px - 26px - 78px - 50px - 28px - 32px - 28px - 28px - 14px);*/
        /*height: calc(100dvh - 28px - 148px - 26px - 78px - 50px - 28px - 32px - 28px - 28px - 14px);*/
        overflow: auto;
        height: 100%;
    }
}