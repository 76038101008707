.input {
    padding: 14px 28px;
    border: 1px solid #3C3C3C;
    width: 100%;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    border-radius: 5px;
    color: #cecece;
    height: 50px;
}

.choose_items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
}

.choose_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #3C3C3C;
    cursor: pointer;
    transition: .3s all;
}

.choose_item_inside {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
    color: #828282;
    transition: .3s all;
}

.choose_item_active {
    border: 1px solid #56CCF2;
    background: #2A3336;
}

.choose_item_active .choose_item_inside {
    color: #56CCF2;
}

.choose_item_active .choose_item_inside svg path {
    fill: #56CCF2;
}

.tags {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: auto;
    height: 60px;
    width: 100%;
}

.tag {
    width: 100%;
    white-space: nowrap;
    padding: 8px 14px;
    height: 31px;
    border-radius: 5px;
    border: 1px solid #3C3C3C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    color: #BDBDBD;
    cursor: pointer;
    transition: .3s all;
}

.tag_active {
    color: #56CCF2;
    border: 1px solid #56CCF2;
    background: #2A3336;
}

.table {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.test {
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow: hidden;
    /*padding-top: 7px;*/
    /*margin-top: -7px;*/
}

.action {
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;
    margin-left: auto;

    margin-bottom: 16px;
}

.pagination {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.header_item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    transition: .4s all;
}

.active_sort {
    color: #56CCF2;
}

.header_item svg path {
    color: #828282;
}


.header_item svg {
    transition: .3s all;
}

.input_box {
    width: 100%;
    /*position: relative;*/
    display: flex;
    align-items: center;
    gap: 8px;
    height: 50px;
}

.search_button {
    width: 120px;
    padding: 14px 28px;
    border: 1px solid #3C3C3C;
    border-radius: 5px;
    color: #cecece;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    cursor: pointer;
    transition: .3s all;

}

.search_button:hover {
    background: rgba(42, 51, 54, 1);
    border: 1px solid #56CCF2;
}

.header_item_active svg {
    rotate: 180deg;
}

.added {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #F2C94C;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: center;

}

.select {
    margin-left: auto;
    min-width: 150px;
    width: fit-content;
    /*border: 1px solid red;*/
}

.select_and_table_box {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 400px;
}

.filter_classname {
    position: relative;
    width: fit-content;
    min-height: 38px !important;
    display: flex;
    align-items: center;
    gap: 6px;
}

.filter_count {

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #56CCF2;
    background: #2A3336;

    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;

    text-align: center;
    color: #56CCF2;
    /*z-index: 9999999;*/
    /*transform: translate(50%, -50%);*/
}